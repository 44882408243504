// source: diamonds_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
goog.exportSymbol('proto.jp.singcolor.Bank', null, global);
goog.exportSymbol('proto.jp.singcolor.BankAccount', null, global);
goog.exportSymbol('proto.jp.singcolor.BankAccountCreditCheckStatus', null, global);
goog.exportSymbol('proto.jp.singcolor.BankAccountType', null, global);
goog.exportSymbol('proto.jp.singcolor.BankBranch', null, global);
goog.exportSymbol('proto.jp.singcolor.BankTransferRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.DiamondAmount', null, global);
goog.exportSymbol('proto.jp.singcolor.DiamondBalance', null, global);
goog.exportSymbol('proto.jp.singcolor.DiamondExchangeToCoinPlan', null, global);
goog.exportSymbol('proto.jp.singcolor.DiamondInOutReason', null, global);
goog.exportSymbol('proto.jp.singcolor.DiamondType', null, global);
goog.exportSymbol('proto.jp.singcolor.ExchangeableToCoinDiamondType', null, global);
goog.exportSymbol('proto.jp.singcolor.Statement', null, global);
goog.exportSymbol('proto.jp.singcolor.WithdrawDiamondRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.WithdrawableDiamondType', null, global);
goog.exportSymbol('proto.jp.singcolor.WithdrawalDiamondLedger', null, global);
goog.exportSymbol('proto.jp.singcolor.WithdrawalDiamondLedgerStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DiamondAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DiamondAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.DiamondAmount.displayName = 'proto.jp.singcolor.DiamondAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DiamondBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DiamondBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.DiamondBalance.displayName = 'proto.jp.singcolor.DiamondBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WithdrawDiamondRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WithdrawDiamondRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.WithdrawDiamondRequest.displayName = 'proto.jp.singcolor.WithdrawDiamondRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DiamondExchangeToCoinPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.DiamondExchangeToCoinPlan.displayName = 'proto.jp.singcolor.DiamondExchangeToCoinPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Bank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Bank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Bank.displayName = 'proto.jp.singcolor.Bank';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BankBranch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BankBranch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.BankBranch.displayName = 'proto.jp.singcolor.BankBranch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BankAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BankAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.BankAccount.displayName = 'proto.jp.singcolor.BankAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.WithdrawalDiamondLedger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.WithdrawalDiamondLedger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.WithdrawalDiamondLedger.displayName = 'proto.jp.singcolor.WithdrawalDiamondLedger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.Statement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.Statement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.Statement.displayName = 'proto.jp.singcolor.Statement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BankTransferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.BankTransferRequest.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.BankTransferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.jp.singcolor.BankTransferRequest.displayName = 'proto.jp.singcolor.BankTransferRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.DiamondAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.DiamondAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.DiamondAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DiamondAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amountText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.DiamondAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.DiamondAmount;
  return proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DiamondAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DiamondAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DiamondAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAmountText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 amount = 1;
 * @return {number}
 */
proto.jp.singcolor.DiamondAmount.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DiamondAmount} returns this
 */
proto.jp.singcolor.DiamondAmount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string amount_text = 2;
 * @return {string}
 */
proto.jp.singcolor.DiamondAmount.prototype.getAmountText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DiamondAmount} returns this
 */
proto.jp.singcolor.DiamondAmount.prototype.setAmountText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.DiamondBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.DiamondBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.DiamondBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DiamondBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    normal: (f = msg.getNormal()) && proto.jp.singcolor.DiamondAmount.toObject(includeInstance, f),
    unexchangeableToCoinAmount: (f = msg.getUnexchangeableToCoinAmount()) && proto.jp.singcolor.DiamondAmount.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && proto.jp.singcolor.DiamondAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.DiamondBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.DiamondBalance;
  return proto.jp.singcolor.DiamondBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DiamondBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.DiamondBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.jp.singcolor.DiamondAmount;
      reader.readMessage(value,proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader);
      msg.setNormal(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.DiamondAmount;
      reader.readMessage(value,proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader);
      msg.setUnexchangeableToCoinAmount(value);
      break;
    case 3:
      var value = new proto.jp.singcolor.DiamondAmount;
      reader.readMessage(value,proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DiamondBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.DiamondBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DiamondBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DiamondBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNormal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter
    );
  }
  f = message.getUnexchangeableToCoinAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional DiamondAmount normal = 1;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.DiamondBalance.prototype.getNormal = function() {
  return /** @type{?proto.jp.singcolor.DiamondAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.DiamondAmount, 1));
};


/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.DiamondBalance} returns this
*/
proto.jp.singcolor.DiamondBalance.prototype.setNormal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DiamondBalance} returns this
 */
proto.jp.singcolor.DiamondBalance.prototype.clearNormal = function() {
  return this.setNormal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DiamondBalance.prototype.hasNormal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DiamondAmount unexchangeable_to_coin_amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.DiamondBalance.prototype.getUnexchangeableToCoinAmount = function() {
  return /** @type{?proto.jp.singcolor.DiamondAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.DiamondAmount, 2));
};


/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.DiamondBalance} returns this
*/
proto.jp.singcolor.DiamondBalance.prototype.setUnexchangeableToCoinAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DiamondBalance} returns this
 */
proto.jp.singcolor.DiamondBalance.prototype.clearUnexchangeableToCoinAmount = function() {
  return this.setUnexchangeableToCoinAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DiamondBalance.prototype.hasUnexchangeableToCoinAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DiamondAmount organization = 3;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.DiamondBalance.prototype.getOrganization = function() {
  return /** @type{?proto.jp.singcolor.DiamondAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.DiamondAmount, 3));
};


/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.DiamondBalance} returns this
*/
proto.jp.singcolor.DiamondBalance.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DiamondBalance} returns this
 */
proto.jp.singcolor.DiamondBalance.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DiamondBalance.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.WithdrawDiamondRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.WithdrawDiamondRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WithdrawDiamondRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: (f = msg.getAmount()) && proto.jp.singcolor.DiamondAmount.toObject(includeInstance, f),
    withdrawableDiamondType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    taxWithholding: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amountAfterTax: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest}
 */
proto.jp.singcolor.WithdrawDiamondRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.WithdrawDiamondRequest;
  return proto.jp.singcolor.WithdrawDiamondRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WithdrawDiamondRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest}
 */
proto.jp.singcolor.WithdrawDiamondRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.DiamondAmount;
      reader.readMessage(value,proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (reader.readEnum());
      msg.setWithdrawableDiamondType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaxWithholding(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountAfterTax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.WithdrawDiamondRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WithdrawDiamondRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WithdrawDiamondRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter
    );
  }
  f = message.getWithdrawableDiamondType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTaxWithholding();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAmountAfterTax();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int64 created_at = 1;
 * @return {number}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest} returns this
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DiamondAmount amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.getAmount = function() {
  return /** @type{?proto.jp.singcolor.DiamondAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.DiamondAmount, 2));
};


/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest} returns this
*/
proto.jp.singcolor.WithdrawDiamondRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest} returns this
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WithdrawableDiamondType withdrawable_diamond_type = 3;
 * @return {!proto.jp.singcolor.WithdrawableDiamondType}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.getWithdrawableDiamondType = function() {
  return /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.WithdrawableDiamondType} value
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest} returns this
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.setWithdrawableDiamondType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 tax_withholding = 4;
 * @return {number}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.getTaxWithholding = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest} returns this
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.setTaxWithholding = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 amount_after_tax = 5;
 * @return {number}
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.getAmountAfterTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WithdrawDiamondRequest} returns this
 */
proto.jp.singcolor.WithdrawDiamondRequest.prototype.setAmountAfterTax = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.DiamondExchangeToCoinPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.DiamondExchangeToCoinPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requireDiamondAmount: (f = msg.getRequireDiamondAmount()) && proto.jp.singcolor.DiamondAmount.toObject(includeInstance, f),
    coinAmount: (f = msg.getCoinAmount()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
    available: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.DiamondExchangeToCoinPlan;
  return proto.jp.singcolor.DiamondExchangeToCoinPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DiamondExchangeToCoinPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.DiamondAmount;
      reader.readMessage(value,proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader);
      msg.setRequireDiamondAmount(value);
      break;
    case 3:
      var value = new coins_entity_pb.CoinAmount;
      reader.readMessage(value,coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
      msg.setCoinAmount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.DiamondExchangeToCoinPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DiamondExchangeToCoinPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequireDiamondAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter
    );
  }
  f = message.getCoinAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      coins_entity_pb.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getAvailable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan} returns this
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DiamondAmount require_diamond_amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.getRequireDiamondAmount = function() {
  return /** @type{?proto.jp.singcolor.DiamondAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.DiamondAmount, 2));
};


/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan} returns this
*/
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.setRequireDiamondAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan} returns this
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.clearRequireDiamondAmount = function() {
  return this.setRequireDiamondAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.hasRequireDiamondAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CoinAmount coin_amount = 3;
 * @return {?proto.jp.singcolor.CoinAmount}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.getCoinAmount = function() {
  return /** @type{?proto.jp.singcolor.CoinAmount} */ (
    jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 3));
};


/**
 * @param {?proto.jp.singcolor.CoinAmount|undefined} value
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan} returns this
*/
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.setCoinAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan} returns this
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.clearCoinAmount = function() {
  return this.setCoinAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.hasCoinAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool available = 4;
 * @return {boolean}
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.getAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.DiamondExchangeToCoinPlan} returns this
 */
proto.jp.singcolor.DiamondExchangeToCoinPlan.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Bank.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Bank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Bank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Bank.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kana: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Bank}
 */
proto.jp.singcolor.Bank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Bank;
  return proto.jp.singcolor.Bank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Bank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Bank}
 */
proto.jp.singcolor.Bank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKana(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Bank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Bank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Bank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Bank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKana();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.jp.singcolor.Bank.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Bank} returns this
 */
proto.jp.singcolor.Bank.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.Bank.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Bank} returns this
 */
proto.jp.singcolor.Bank.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string kana = 3;
 * @return {string}
 */
proto.jp.singcolor.Bank.prototype.getKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Bank} returns this
 */
proto.jp.singcolor.Bank.prototype.setKana = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.BankBranch.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.BankBranch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.BankBranch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BankBranch.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kana: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BankBranch}
 */
proto.jp.singcolor.BankBranch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.BankBranch;
  return proto.jp.singcolor.BankBranch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BankBranch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BankBranch}
 */
proto.jp.singcolor.BankBranch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKana(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BankBranch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.BankBranch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BankBranch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BankBranch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKana();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.jp.singcolor.BankBranch.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankBranch} returns this
 */
proto.jp.singcolor.BankBranch.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.BankBranch.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankBranch} returns this
 */
proto.jp.singcolor.BankBranch.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string kana = 3;
 * @return {string}
 */
proto.jp.singcolor.BankBranch.prototype.getKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankBranch} returns this
 */
proto.jp.singcolor.BankBranch.prototype.setKana = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.BankAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.BankAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.BankAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BankAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bankCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    branchCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    address: jspb.Message.getFieldWithDefault(msg, 9, ""),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    kanaName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 12, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    companyAddress: jspb.Message.getFieldWithDefault(msg, 14, ""),
    companyPostalCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    companyRepresentativeName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 17, ""),
    invoiceRegistrationName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    invoiceRegistrationDate: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BankAccount}
 */
proto.jp.singcolor.BankAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.BankAccount;
  return proto.jp.singcolor.BankAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BankAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BankAccount}
 */
proto.jp.singcolor.BankAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchCode(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.BankAccountType} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setKanaName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthday(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyAddress(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyPostalCode(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyRepresentativeName(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceRegistrationName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceRegistrationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BankAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.BankAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BankAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BankAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBranchCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getKanaName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBirthday();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCompanyAddress();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCompanyPostalCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCompanyRepresentativeName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getInvoiceRegistrationName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getInvoiceRegistrationDate();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string bank_account_id = 1;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getBankAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setBankAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bank_code = 3;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getBankCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setBankCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string branch_code = 4;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getBranchCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setBranchCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional BankAccountType account_type = 5;
 * @return {!proto.jp.singcolor.BankAccountType}
 */
proto.jp.singcolor.BankAccount.prototype.getAccountType = function() {
  return /** @type {!proto.jp.singcolor.BankAccountType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.BankAccountType} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string account_number = 6;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_name = 7;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string address = 9;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string kana_name = 11;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getKanaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setKanaName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string birthday = 12;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getBirthday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string company_name = 13;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string company_address = 14;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getCompanyAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setCompanyAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string company_postal_code = 15;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getCompanyPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setCompanyPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string company_representative_name = 16;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getCompanyRepresentativeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setCompanyRepresentativeName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string invoice_number = 17;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string invoice_registration_name = 18;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getInvoiceRegistrationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setInvoiceRegistrationName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string invoice_registration_date = 19;
 * @return {string}
 */
proto.jp.singcolor.BankAccount.prototype.getInvoiceRegistrationDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankAccount} returns this
 */
proto.jp.singcolor.BankAccount.prototype.setInvoiceRegistrationDate = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.WithdrawalDiamondLedger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.WithdrawalDiamondLedger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WithdrawalDiamondLedger.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawalDiamondTransactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    diamondAmount: (f = msg.getDiamondAmount()) && proto.jp.singcolor.DiamondAmount.toObject(includeInstance, f),
    withdrawableDiamondType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    exchangeRate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    date: jspb.Message.getFieldWithDefault(msg, 6, 0),
    transferAmount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    consumptionTaxDeducationAmount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    invoiceBusinessName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.WithdrawalDiamondLedger;
  return proto.jp.singcolor.WithdrawalDiamondLedger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.WithdrawalDiamondLedger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithdrawalDiamondTransactionId(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.DiamondAmount;
      reader.readMessage(value,proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader);
      msg.setDiamondAmount(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (reader.readEnum());
      msg.setWithdrawableDiamondType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeRate(value);
      break;
    case 5:
      var value = /** @type {!proto.jp.singcolor.WithdrawalDiamondLedgerStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTransferAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConsumptionTaxDeducationAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceBusinessName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.WithdrawalDiamondLedger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.WithdrawalDiamondLedger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.WithdrawalDiamondLedger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawalDiamondTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiamondAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter
    );
  }
  f = message.getWithdrawableDiamondType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getExchangeRate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTransferAmount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getConsumptionTaxDeducationAmount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getInvoiceBusinessName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string withdrawal_diamond_transaction_id = 1;
 * @return {string}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getWithdrawalDiamondTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setWithdrawalDiamondTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DiamondAmount diamond_amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getDiamondAmount = function() {
  return /** @type{?proto.jp.singcolor.DiamondAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.DiamondAmount, 2));
};


/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
*/
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setDiamondAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.clearDiamondAmount = function() {
  return this.setDiamondAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.hasDiamondAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WithdrawableDiamondType withdrawable_diamond_type = 3;
 * @return {!proto.jp.singcolor.WithdrawableDiamondType}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getWithdrawableDiamondType = function() {
  return /** @type {!proto.jp.singcolor.WithdrawableDiamondType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.WithdrawableDiamondType} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setWithdrawableDiamondType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string exchange_rate = 4;
 * @return {string}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getExchangeRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setExchangeRate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional WithdrawalDiamondLedgerStatus status = 5;
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedgerStatus}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getStatus = function() {
  return /** @type {!proto.jp.singcolor.WithdrawalDiamondLedgerStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.jp.singcolor.WithdrawalDiamondLedgerStatus} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 date = 6;
 * @return {number}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 transfer_amount = 7;
 * @return {number}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getTransferAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setTransferAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 consumption_tax_deducation_amount = 8;
 * @return {number}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getConsumptionTaxDeducationAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setConsumptionTaxDeducationAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string invoice_business_name = 9;
 * @return {string}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getInvoiceBusinessName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setInvoiceBusinessName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string invoice_number = 10;
 * @return {string}
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger} returns this
 */
proto.jp.singcolor.WithdrawalDiamondLedger.prototype.setInvoiceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.Statement.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.Statement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.Statement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Statement.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0),
    inOutDiamondAmount: (f = msg.getInOutDiamondAmount()) && proto.jp.singcolor.DiamondAmount.toObject(includeInstance, f),
    diamondType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 4, 0),
    note: jspb.Message.getFieldWithDefault(msg, 5, ""),
    acquiredDiamondDetail: (f = msg.getAcquiredDiamondDetail()) && users_entity_pb.AcquiredDiamondDetail.toObject(includeInstance, f),
    termLabel: jspb.Message.getFieldWithDefault(msg, 7, ""),
    exchangeRate: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.Statement}
 */
proto.jp.singcolor.Statement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.Statement;
  return proto.jp.singcolor.Statement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.Statement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.Statement}
 */
proto.jp.singcolor.Statement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.jp.singcolor.DiamondAmount;
      reader.readMessage(value,proto.jp.singcolor.DiamondAmount.deserializeBinaryFromReader);
      msg.setInOutDiamondAmount(value);
      break;
    case 3:
      var value = /** @type {!proto.jp.singcolor.DiamondType} */ (reader.readEnum());
      msg.setDiamondType(value);
      break;
    case 4:
      var value = /** @type {!proto.jp.singcolor.DiamondInOutReason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 6:
      var value = new users_entity_pb.AcquiredDiamondDetail;
      reader.readMessage(value,users_entity_pb.AcquiredDiamondDetail.deserializeBinaryFromReader);
      msg.setAcquiredDiamondDetail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTermLabel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.Statement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.Statement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.Statement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.Statement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInOutDiamondAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.jp.singcolor.DiamondAmount.serializeBinaryToWriter
    );
  }
  f = message.getDiamondType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAcquiredDiamondDetail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      users_entity_pb.AcquiredDiamondDetail.serializeBinaryToWriter
    );
  }
  f = message.getTermLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExchangeRate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.jp.singcolor.Statement.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DiamondAmount in_out_diamond_amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.Statement.prototype.getInOutDiamondAmount = function() {
  return /** @type{?proto.jp.singcolor.DiamondAmount} */ (
    jspb.Message.getWrapperField(this, proto.jp.singcolor.DiamondAmount, 2));
};


/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.Statement} returns this
*/
proto.jp.singcolor.Statement.prototype.setInOutDiamondAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.clearInOutDiamondAmount = function() {
  return this.setInOutDiamondAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Statement.prototype.hasInOutDiamondAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DiamondType diamond_type = 3;
 * @return {!proto.jp.singcolor.DiamondType}
 */
proto.jp.singcolor.Statement.prototype.getDiamondType = function() {
  return /** @type {!proto.jp.singcolor.DiamondType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.jp.singcolor.DiamondType} value
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.setDiamondType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DiamondInOutReason reason = 4;
 * @return {!proto.jp.singcolor.DiamondInOutReason}
 */
proto.jp.singcolor.Statement.prototype.getReason = function() {
  return /** @type {!proto.jp.singcolor.DiamondInOutReason} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.jp.singcolor.DiamondInOutReason} value
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.jp.singcolor.Statement.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AcquiredDiamondDetail acquired_diamond_detail = 6;
 * @return {?proto.jp.singcolor.AcquiredDiamondDetail}
 */
proto.jp.singcolor.Statement.prototype.getAcquiredDiamondDetail = function() {
  return /** @type{?proto.jp.singcolor.AcquiredDiamondDetail} */ (
    jspb.Message.getWrapperField(this, users_entity_pb.AcquiredDiamondDetail, 6));
};


/**
 * @param {?proto.jp.singcolor.AcquiredDiamondDetail|undefined} value
 * @return {!proto.jp.singcolor.Statement} returns this
*/
proto.jp.singcolor.Statement.prototype.setAcquiredDiamondDetail = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.clearAcquiredDiamondDetail = function() {
  return this.setAcquiredDiamondDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.Statement.prototype.hasAcquiredDiamondDetail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string term_label = 7;
 * @return {string}
 */
proto.jp.singcolor.Statement.prototype.getTermLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.setTermLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string exchange_rate = 8;
 * @return {string}
 */
proto.jp.singcolor.Statement.prototype.getExchangeRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.Statement} returns this
 */
proto.jp.singcolor.Statement.prototype.setExchangeRate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.BankTransferRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.jp.singcolor.BankTransferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.jp.singcolor.BankTransferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.jp.singcolor.BankTransferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BankTransferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankTransferRequestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gmoApplyNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transferDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    withdrawalDiamondLedgersList: jspb.Message.toObjectList(msg.getWithdrawalDiamondLedgersList(),
    proto.jp.singcolor.WithdrawalDiamondLedger.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BankTransferRequest}
 */
proto.jp.singcolor.BankTransferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.jp.singcolor.BankTransferRequest;
  return proto.jp.singcolor.BankTransferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BankTransferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BankTransferRequest}
 */
proto.jp.singcolor.BankTransferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankTransferRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGmoApplyNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferDate(value);
      break;
    case 4:
      var value = new proto.jp.singcolor.WithdrawalDiamondLedger;
      reader.readMessage(value,proto.jp.singcolor.WithdrawalDiamondLedger.deserializeBinaryFromReader);
      msg.addWithdrawalDiamondLedgers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BankTransferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.jp.singcolor.BankTransferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BankTransferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BankTransferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankTransferRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGmoApplyNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransferDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWithdrawalDiamondLedgersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.jp.singcolor.WithdrawalDiamondLedger.serializeBinaryToWriter
    );
  }
};


/**
 * optional string bank_transfer_request_id = 1;
 * @return {string}
 */
proto.jp.singcolor.BankTransferRequest.prototype.getBankTransferRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankTransferRequest} returns this
 */
proto.jp.singcolor.BankTransferRequest.prototype.setBankTransferRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string gmo_apply_no = 2;
 * @return {string}
 */
proto.jp.singcolor.BankTransferRequest.prototype.getGmoApplyNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankTransferRequest} returns this
 */
proto.jp.singcolor.BankTransferRequest.prototype.setGmoApplyNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transfer_date = 3;
 * @return {string}
 */
proto.jp.singcolor.BankTransferRequest.prototype.getTransferDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BankTransferRequest} returns this
 */
proto.jp.singcolor.BankTransferRequest.prototype.setTransferDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated WithdrawalDiamondLedger withdrawal_diamond_ledgers = 4;
 * @return {!Array<!proto.jp.singcolor.WithdrawalDiamondLedger>}
 */
proto.jp.singcolor.BankTransferRequest.prototype.getWithdrawalDiamondLedgersList = function() {
  return /** @type{!Array<!proto.jp.singcolor.WithdrawalDiamondLedger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.WithdrawalDiamondLedger, 4));
};


/**
 * @param {!Array<!proto.jp.singcolor.WithdrawalDiamondLedger>} value
 * @return {!proto.jp.singcolor.BankTransferRequest} returns this
*/
proto.jp.singcolor.BankTransferRequest.prototype.setWithdrawalDiamondLedgersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.jp.singcolor.WithdrawalDiamondLedger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.WithdrawalDiamondLedger}
 */
proto.jp.singcolor.BankTransferRequest.prototype.addWithdrawalDiamondLedgers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.jp.singcolor.WithdrawalDiamondLedger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.BankTransferRequest} returns this
 */
proto.jp.singcolor.BankTransferRequest.prototype.clearWithdrawalDiamondLedgersList = function() {
  return this.setWithdrawalDiamondLedgersList([]);
};


/**
 * @enum {number}
 */
proto.jp.singcolor.DiamondType = {
  DIAMOND_TYPE_NORMAL: 0,
  DIAMOND_TYPE_UNEXCHANGEABLE_TO_COIN: 1,
  DIAMOND_TYPE_ORGANIZATION: 2
};

/**
 * @enum {number}
 */
proto.jp.singcolor.ExchangeableToCoinDiamondType = {
  EXCHANGEABLE_TO_COIN_DIAMOND_TYPE_NORMAL: 0,
  EXCHANGEABLE_TO_COIN_DIAMOND_TYPE_ORGANIZATION: 1
};

/**
 * @enum {number}
 */
proto.jp.singcolor.WithdrawableDiamondType = {
  WITHDRAWABLE_DIAMOND_TYPE_NOT_ORGANIZATION: 0,
  WITHDRAWABLE_DIAMOND_TYPE_ORGANIZATION: 1
};

/**
 * @enum {number}
 */
proto.jp.singcolor.DiamondInOutReason = {
  DIAMOND_IN_OUT_REASON_WITHDRAW_EXCHANGE_TO_CURRENCY: 0,
  DIAMOND_IN_OUT_REASON_EXCHANGE_TO_COIN: 1,
  DIAMOND_IN_OUT_REASON_WABI: 3,
  DIAMOND_IN_OUT_REASON_SING_AND_LIVE: 4,
  DIAMOND_IN_OUT_REASON_SURGE_RANKING_NO1_REWARD: 11,
  DIAMOND_IN_OUT_REASON_WITHDRAWAL_REFUND: 12,
  DIAMOND_IN_OUT_REASON_PAID_TO_TSUNAGU: 13,
  DIAMOND_IN_OUT_REASON_TSUNAGU_PAYMENT_CANCELLED: 14,
  DIAMOND_IN_OUT_REASON_TSUNAGU_TRANSACTION_CANCELLED: 15,
  DIAMOND_IN_OUT_REASON_SUBTRACTION: 16,
  DIAMOND_IN_OUT_REASON_ONE_PHRASE_REWARD: 17
};

/**
 * @enum {number}
 */
proto.jp.singcolor.BankAccountType = {
  BANK_ACCOUNT_TYPE_UNKNOWN: 0,
  BANK_ACCOUNT_TYPE_ORDINARY_DEPOSIT: 1,
  BANK_ACCOUNT_TYPE_CHECKING: 2,
  BANK_ACCOUNT_TYPE_SAVINGS: 4,
  BANK_ACCOUNT_TYPE_OTHER: 9
};

/**
 * @enum {number}
 */
proto.jp.singcolor.BankAccountCreditCheckStatus = {
  BANK_ACCOUNT_CREDIT_CHECK_STATUS_UNKNOWN: 0,
  BANK_ACCOUNT_CREDIT_CHECK_STATUS_NOT_YET: 1,
  BANK_ACCOUNT_CREDIT_CHECK_STATUS_IN_PROGRESS: 2,
  BANK_ACCOUNT_CREDIT_CHECK_STATUS_APPROVED: 3,
  BANK_ACCOUNT_CREDIT_CHECK_STATUS_REJECTED: 4
};

/**
 * @enum {number}
 */
proto.jp.singcolor.WithdrawalDiamondLedgerStatus = {
  WITHDRAWAL_DIAMOND_LEDGER_STATUS_ACCEPTED: 0,
  WITHDRAWAL_DIAMOND_LEDGER_STATUS_WAIT_FOR_BANK_TRANSFER_REQUEST: 1,
  WITHDRAWAL_DIAMOND_LEDGER_STATUS_WAIT_FOR_BANK_TRANSFER: 2,
  WITHDRAWAL_DIAMOND_LEDGER_STATUS_COMPLETED: 3,
  WITHDRAWAL_DIAMOND_LEDGER_STATUS_FAILED: 4
};

goog.object.extend(exports, proto.jp.singcolor);
